<template>
  <div class="form-upload-wrapper">
    <el-upload
      class="avatar-uploader"
      action="/"
      :accept="settings.accept"
      :before-upload="beforeUpload"
      :http-request="httpRequest"
      :show-file-list="false"
    >
      <slot>
        <el-button type="primary">上传文件</el-button>
      </slot>
    </el-upload>
    <div class="fileList">
      <div class="tip" v-if="fileInfo.id">
        <div class="text ellipsis">{{ fileInfo.name }}</div>
        <div class="close">
          <i class="icon el-icon-circle-close" @click="deleFile"></i>
        </div>
      </div>
      <div class="placeholder" v-else>{{ placeholder }}</div>
    </div>
  </div>
</template>
<script>
import { useFileUpload } from '@/hooks/upload'
import { onUnmounted, reactive, watch } from 'vue'
import { getFileInfo } from '@/apis'
export default {
  name: 'formUpload',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    accept: {
      type: String,
      default: '.png,.jpg'
    },
    size: {
      type: String,
      default: '20MB'
    },
    placeholder: {
      type: String
    }
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const fileInfo = reactive({
      name: '',
      fileId: ''
    })
    const { settings, beforeUpload, httpRequest } = useFileUpload(
      file => {
        emit('update:modelValue', file.id)
        emit('change', file)
        fileInfo.id = file.id
        fileInfo.name = file.name
      },
      { accept: props.accept, limit: 1, size: props.size }
    )
    const deleFile = () => {
      fileInfo.id = ''
      fileInfo.name = ''
      emit('update:modelValue', '')
      emit('change', null)
    }
    const getFileInfoFn = async id => {
      try {
        const { data } = await getFileInfo(id)
        if (data) {
          fileInfo.id = data.id
          fileInfo.name = data.name
        }
      } catch (error) {
        console.log('getFileInfoFn error', error)
      }
    }
    const stop = watch(
      () => props.modelValue,
      id => {
        if (id && id !== fileInfo.id) {
          // 获取附件信息
          // getFileInfo()
          getFileInfoFn(id)
        }
      }
    )
    onUnmounted(() => {
      stop && stop()
    })
    return { fileInfo, beforeUpload, settings, httpRequest, deleFile }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
