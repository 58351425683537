<template>
  <div class="community-select">
    <div class="trigger" v-if="showTrigger" @click.stop="handleTrigger">
      <template v-if="state.selectList.length">
        <slot name="label" :list="state.selectList">
          <el-tooltip :content="viewText" placement="top" effect="light">
            <div class="view-text ellipsis">
              {{ state.selectList.map(item => item.name).join(',') }}
            </div>
          </el-tooltip>
        </slot>
      </template>
      <div v-else class="placeholder">{{ placeholder }}</div>
      <!-- <div class="select-item" v-for="department in state.selectList" :key="department.id">{{ department.name }}</div> -->
    </div>
    <transfer-dialog
      title="选择小区"
      selectType="community"
      children-key="childList"
      v-model:visible="state.visible"
      :lists="state.list"
      :root-name="state.rootName"
      :root-id="state.rootId"
      :selecteds="state.selectList"
      :single="single"
      @confirm="confirm"
      @cancel="cancel"
      :search="onSearch"
    ></transfer-dialog>
  </div>
</template>
<script>
import TransferDialog from '@/components/transfer-dialog/index.vue'
import { computed, onUnmounted, reactive, watch } from 'vue'
import { getAllCommunityList } from '@/apis'
import { useUser } from '@/hooks'
export default {
  name: 'communitySelect',
  components: { TransferDialog },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    single: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '选择部门'
    },
    showTrigger: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:modelValue', 'ok', 'confirm', 'change'],
  setup(props, { emit }) {
    const updateValue = value => {
      emit('update:modelValue', value) // 传递的方法
    }
    const { user } = useUser()
    const state = reactive({
      list: [],
      visible: false,
      selectList: [],
      rootName: '',
      rootId: '',
      rootData: []
    })
    const viewText = computed(() => {
      return state.selectList.map(item => item.name).join('、')
    })
    const resetList = (checked = false) => {
      state.list.forEach(item => (item.checked = checked))
    }
    const initCurrentData = async name => {
      try {
        const result = await getAllCommunityList({ name })
        const { data = [] } = result
        data.unshift({ name: '默认(全部项目)', id: '000000000000000000' })
        state.list = data.map(item => {
          item['type'] = 'community'
          if (props.modelValue && props.modelValue.includes(item.id)) {
            state.selectList.push(item)
          }
          return item
        })
        state.rootName = user.value.companyName
        if (!name) {
          state.rootData = state.list
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    initCurrentData()
    // 监听选中数据，做回显操作
    const stop = watch(
      () => props.modelValue,
      value => {
        if (value && value.length) {
          state.selectList = []
          if (state.list.length) {
            state.list.forEach(item => {
              if (props.modelValue.includes(item.id)) {
                state.selectList.push(item)
              }
            })
          }
        } else {
          state.selectList = []
          emit('ok', props.modelValue, state?.selectList ?? [])
        }
      },
      { immediate: true, deep: true }
    )
    onUnmounted(() => {
      typeof stop === 'function' && stop()
    })
    const handleTrigger = () => {
      state.visible = true
    }
    const confirm = list => {
      state.selectList = list
      const ids = list.map(item => item.id)
      updateValue(ids)
      emit('ok', ids, list)
      emit('confirm', ids, list)
      emit('change', ids)
    }
    const cancel = () => {
      state.visible = false
    }
    const getSelectedList = () => {
      return state.selectList
    }
    const triggerDialog = bool => {
      state.selectList = []
      resetList(false)
      state.visible = bool
    }
    const onSearch = text => {
      if (!text) {
        state.list = state.rootData
      } else {
        initCurrentData(text)
      }
    }
    return { state, viewText, handleTrigger, confirm, cancel, getSelectedList, triggerDialog, onSearch }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
