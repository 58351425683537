<template>
  <div class="merchant-create-page">
    <div class="title">{{ form.mchId ? '商户号编辑' : '商户号添加' }}</div>
    <div class="form-wrapper">
      <el-form ref="merchantRef" :rules="rules" size="small" label-position="right" label-width="160px" :model="form">
        <el-form-item prop="community" label="项目：">
          <community-select
            @ok="handleCommunityOk"
            placeholder="请选择小区"
            single
            v-model="form.community"
          ></community-select>
        </el-form-item>
        <el-form-item prop="mchModel" label="服务类型：">
          <el-radio-group v-model="form.mchModel">
            <el-radio :label="false">服务商模式</el-radio>
            <el-radio :label="true">普通商户模式</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="mchName" label="商户号名称：">
          <el-input v-model="form.mchName" placeholder="输入商户名称"></el-input>
        </el-form-item>
        <el-form-item prop="mchId" label="支付商户号：">
          <el-input v-model="form.mchId" placeholder="输入支付商户号"></el-input>
        </el-form-item>
        <template v-if="form.mchModel">
          <el-form-item prop="mchSecret" label="商户支付密钥Key：">
            <el-input v-model="form.mchSecret" placeholder="输入商户支付密钥Key"></el-input>
          </el-form-item>
          <el-form-item prop="appId" label="小程序AppId：">
            <el-input v-model="form.appId" placeholder="输入小程序AppId"></el-input>
          </el-form-item>
          <el-form-item prop="purAppId" label="公众号AppId：">
            <el-input v-model="form.purAppId" placeholder="输入公众号AppId"></el-input>
          </el-form-item>
          <el-form-item prop="mchFileId" label="pkcs12格式：">
            <div class="file-item">
              <form-upload
                accept=".p12"
                placeholder="支付证书 apiclient_cert.p12"
                v-model="form.mchFileId"
              ></form-upload>
            </div>
          </el-form-item>
          <el-form-item prop="certPemFileId" label="证书pem格式：">
            <div class="file-item">
              <form-upload
                accept=".pem"
                placeholder="支付证书 apiclient_cert.pem"
                v-model="form.certPemFileId"
              ></form-upload>
            </div>
          </el-form-item>
          <el-form-item prop="keyPemFileId" label="证书密钥pem：">
            <div class="file-item">
              <form-upload
                accept=".pem"
                placeholder="支付证书 apiclient_key.pem"
                v-model="form.keyPemFileId"
              ></form-upload>
            </div>
          </el-form-item>
        </template>
        <el-form-item label="">
          <el-button type="primary" :loading="loading" class="btn-item" @click="onConfirm">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { reactive, ref ,watch} from 'vue'
import FormUpload from '@/components/form-upload'
import CommunitySelect from '@/components/community-select'
import { createMerchant, getMerchantById, updateMerchant } from '@/apis'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'

export default {
  name: 'createMerchant',
  components: { FormUpload, CommunitySelect },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const id = route.params.id
    const merchantRef = ref(null)
    const loading = ref(false)
    const form = reactive({
      community: [],
      communityName: '',
      communityNo: '', // 项目
      mchModel: false, // 服务商类型
      merchantSettingId: '', //服务商ID
      mchId: '', // 商户Id
      mchName: '', // 商户名称
      mchSecret: '', // 商户密钥
      appId: '', // 商户小程序appId
      purAppId: '', // 商户公众号appId
      mchFileId: '', //支付文件
      certPemFileId: '', // 支付证书apiclient_cert.pem
      keyPemFileId: '' // 支付证书apiclient_key.pem
    })
    const communityValidator = (rule, value, callback) => {
      if (value && value.length) return callback()
      callback(rule.message)
    }
    const rules = {
      community: {
        required: true,
        validator: communityValidator,
        message: '请选择项目',
        trigger: ['change', 'blur']
      },
      mchModel: {
        required: true,
        message: '请选择服务类型',
        trigger: ['change']
      },
      mchName: {
        required: true,
        message: '请输入商户名称',
        trigger: ['blur', 'change']
      },
      mchId: {
        required: true,
        message: '请输入支付商户号',
        trigger: ['blur', 'change']
      },
      mchSecret: {
        required: true,
        message: '请输入支付密钥Key',
        trigger: ['blur', 'change']
      },
      appId: {
        required: true,
        message: '请输入小程序AppId',
        trigger: ['blur', 'change']
      },
      purAppId: {
        required: true,
        message: '请输入公众号AppId',
        trigger: ['blur', 'change']
      }
    }
    const handleCommunityOk = (id, lists) => {
      const [community] = lists
      if (community) {
        form.communityNo = community.id
        form.communityName = community.name
      } else {
        form.communityNo = ''
        form.communityName = ''
      }
      merchantRef.value && merchantRef.value.validateField('community')
    }
    const handleRequest = async params => {
      try {
        if (id) {
          await updateMerchant(params)
        } else {
          await createMerchant(params)
        }
        ElMessage.success(id ? '修改成功' : '添加成功')
        router.back()
      } catch (error) {
        console.log('handleRequest error', error)
      }
    }
    const onConfirm = () => {
      merchantRef.value.validate(valid => {
        if (!valid) return false
        console.log('form', form)
        const params = {
          communityNo: form.communityNo,
          communityName: form.communityName,
          // merchantSettingId: form.merchantSettingId,
          mchModel: form.mchModel,
          mchId: form.mchId,
          mchSecret: form.mchSecret,
          appId: form.appId,
          purAppId: form.purAppId,
          mchName: form.mchName,
          mchFileId: form.mchFileId,
          certPemFileId: form.certPemFileId,
          keyPemFileId: form.keyPemFileId
        }
        if (id) {
          params['id'] = id
        }
        handleRequest(params)
        console.log('--------------')
      })
    }
    const initMerchantInfo = async () => {
      try {
        const { data } = await getMerchantById(id)
        if (!data) return false
        form.community = [data.communityNo]
        form.communityNo = data.communityNo
        form.communityName = data.communityName
        form.mchModel = data.mchModel
        form.appId = data.appId || ''
        form.purAppId = data.purAppId || ''
        form.mchSecret = data.mchSecret
        form.mchName = data.mchName
        form.mchId = data.mchId
        form.mchFileId = data.mchFileId
        form.certPemFileId = data.certPemFileId
        form.keyPemFileId = data.keyPemFileId
      } catch (error) {
        console.log('initMerchantInfo error', error)
      }
    }
    watch(()=>form.mchModel,(value)=>{
      if(value){
        rules.mchFileId = {
          required: true,
          message: '请上传支付证书 apiclient_cert.p12',
        }
        rules.certPemFileId = {
          required: true,
          message: '请上传支付证书 apiclient_cert.pem',
        }
        rules.keyPemFileId = {
          required: true,
          message: '请上传支付证书 apiclient_key.pem',
        }
      }else {
        delete rules.mchFileId
        delete rules.certPemFileId
        delete rules.keyPemFileId
      }
    })
    if (id) {
      initMerchantInfo()
    }
    return { form, rules, merchantRef, loading, onConfirm, handleCommunityOk }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
